.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.about > h1 {
  font-size: 64px;
  color: #e6f1ff;
  font-weight: 800;
  font-family: poppins;
}
.about__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px 20%;
}
.about__info > h2 {
  font-size: 36px;
  color: lightgray;
  font-weight: 600;
}
.about__info > p {
  font-size: 18px;
  color: #8892b0;
  margin: 20px 0px;
}
.about__info > button {
  width: 150px;
  height: 55px;
  border-radius: 15px;
  border: 1px solid #64ffda;
  background-color: transparent;
  color: #64ffda;
  font-family: PT Mono;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 40px;
}
.cv > .MuiSvgIcon-root {
  margin-top: 0;
  color: #64ffda;
  width: 20px;
  height: 20px;
}
.about__techno {
  border-top: 1px solid #64ffda;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.about__techno > h2 {
  color: #64ffda;
  font-family: PT Mono;
  font-size: 15px;
  font-weight: 400;
  margin-top: 30px;
}
.about__tech {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  margin-top: 50px;
}
.about__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
}
.about__details > h3 {
  font-size: 20px;
  color: #e6f1ff;
  margin-bottom: 20px;
}
.about__details > p {
  color: #e6f1ff;
  font-size: 13px;
}
@media screen and (max-width: 414px) {
  .about__info {
    width: 85%;
  }
  .about > h1 {
    font-size: 36px;
  }
  .about__info > h2 {
    font-size: 24px;
  }
  .about__info > p {
    font-size: 15px;
  }
  .about__techno > h2 {
    text-align: center;
  }
  .about__details > h3 {
    font-size: 15px;
    text-align: center;
  }
  .about__details {
    margin: 0 20px;
  }
  button > p {
    font-size: 13px;
  }
  .about__tech {
    margin-top: 20px;
  }
  .about__info > button {
    width: 100px;
    height: 45px;
    margin-bottom: 10px;
    margin-top: 0;
  }
}
@media screen and (max-width: 390px) {
  .about__details > p {
    color: #e6f1ff;
    font-size: 11px;
  }
}
