.work {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10%;
  position: relative;

  margin-bottom: 10%;
}
.work > h1 {
  font-size: 64px;
  font-weight: 800;
  color: #e6f1ff;
  font-family: poppins;
  margin-bottom: 100px;
}
.work__card1 {
  position: relative;
  margin-bottom: 100px;
  margin-left: 20%;
  transform: translateX(130%);
  transition: transform 0.7s ease-in-out;
}
.showCard {
  transform: translateX(0%);
}
.work__card2 {
  position: relative;
  margin-bottom: 100px;
  margin-right: 20%;
  transform: translateX(-130%);
  transition: transform 1.4s ease-in-out;
}
.showCard2 {
  transform: translateX(0%);
}
.work__card3 {
  position: relative;
  margin-bottom: 100px;
  margin-left: 20%;
  transform: translateX(130%);
  transition: transform 2.1s ease-in-out;
}
.showCard {
  transform: translateX(0%);
}
.work__card1 > img {
  height: 300px;
  width: 650px;
  opacity: 40%;
  box-shadow: 5px 5px 18px 5px rgba(0, 0, 0, 0.829);
}
.work__card2 > img {
  height: 300px;
  width: 650px;
  opacity: 40%;
  box-shadow: -6px 3px 18px 7px rgba(0, 0, 0, 0.842);
}
.work__card3 > img {
  height: 300px;
  width: 650px;
  opacity: 40%;
  box-shadow: -6px 3px 18px 7px rgba(0, 0, 0, 0.836);
}
.work__card4 > img {
  height: 300px;
  width: 650px;
  opacity: 40%;
  box-shadow: -6px 3px 18px 7px rgba(0, 0, 0, 0.836);
}
.work__number1 {
  font-size: 120px;
  font-family: poppins;
  font-weight: 700;
  color: whitesmoke;
  position: absolute;
  top: -80px;
  right: -40px;
}
.work__number2 {
  font-size: 120px;
  font-family: poppins;
  font-weight: 700;
  color: whitesmoke;
  position: absolute;
  top: -80px;
  left: -50px;
}

.work__info1 {
  position: absolute;
  top: 100px;
  left: 250px;
}
.work__info2 {
  position: absolute;
  top: 100px;
  left: 10px;
}
.work__info1 > h1 {
  font-size: 48px;
  font-family: poppins;
  font-weight: 700;
  color: whitesmoke;
}
.work__info2 > h1 {
  font-size: 48px;
  font-family: poppins;
  font-weight: 700;
  color: whitesmoke;
}
.work__info1 > p {
  color: lightgray;
}
.work__info2 > p {
  color: lightgray;
  max-width: 400px;
}
.work__button1 > button {
  background-color: rgb(65, 65, 65);
  border-radius: 17px;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  color: #64ffda;
  font-family: PT Mono;
  background: none;
  border: 1px solid #64ffda;
  box-shadow: 5px 5px 18px 2px rgba(0, 0, 0, 0.43);
}
.work__button2 > button {
  background-color: rgb(65, 65, 65);
  border-radius: 17px;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  color: #64ffda;
  font-family: PT Mono;
  background: none;
  border: 1px solid #64ffda;
  box-shadow: 5px 5px 18px 2px rgba(0, 0, 0, 0.43);
}
.work__button1 {
  position: absolute;
  top: 10px;
  left: 10px;
}
.work__button2 {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media screen and (max-width: 820px) {
  .work {
    margin-bottom: 43%;
  }
  .work__card1 > img {
    width: 450px;
    height: 200px;
  }
  .work__card2 > img {
    width: 450px;
    height: 200px;
  }
  .work__card3 > img {
    width: 450px;
    height: 200px;
  }
  .work__info1 > h1 {
    font-size: 32px;
  }
  .work__info2 > h1 {
    font-size: 32px;
  }
  .work__info1 {
    width: 300px;
    left: 150px;
    top: 50px;
  }
}
@media screen and (max-width: 414px) {
  .work__card1 {
    padding-bottom: 20px;
    margin-left: 0;
  }
  .work__card2 {
    padding-bottom: 20px;
    margin-right: 0;
  }
  .work__card3 {
    padding-bottom: 20px;
    margin-left: 0;
  }
  .work__number1 {
    font-size: 56px;
    top: -40px;
    right: -20px;
  }
  .work__number2 {
    font-size: 56px;
    top: -40px;
    left: -20px;
  }
  .work > h1 {
    font-size: 36px;
  }
  .work__card1 > img {
    width: 270px;
    height: 150px;
  }
  .work__card2 > img {
    width: 270px;
    height: 150px;
  }
  .work__card3 > img {
    width: 270px;
    height: 150px;
  }
  .work__info1 > h1 {
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .work__info1 > p {
    font-size: 12px;
    text-align: end;
  }

  .work__info2 > h1 {
    font-size: 20px;
  }
  .work__info2 > p {
    font-size: 12px;
  }
  .work__info1 {
    width: 197px;
    left: 70px;
    top: 50px;
  }
  .work__info2 {
    width: 200px;
    left: 2px;
    top: 50px;
  }
  .work__icon1 {
    text-align: end;
  }
  .icon {
    margin-top: 3px;
    height: 25px;
    width: 25px;
    object-fit: cover;
  }
  button > a {
    font-size: 10px;
  }
  .work__button1 {
    top: 10px;
    left: 0px;
  }
  .work__button2 {
    top: 10px;
    right: 0px;
  }
  .work__button1 > button {
    margin-right: 15px;
  }
  .work__button2 > button {
    margin-left: 15px;
  }
}
