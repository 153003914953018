.headerOption {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  padding: 10px 0px;
  cursor: pointer;
  width: 100px;
  margin-right: 10px;
}
.headerOption > h3 {
  padding-left: 5px;
  font-size: 15px;
  opacity: 0;
}
.headerOption > .MuiSvgIcon-root {
  background-color: #64ffb9;
  border-radius: 25px;
  padding: 5px;
  color: black;
}
.headerOption:hover {
  background-color: #64ffb9;
  border-radius: 25px;
  padding: 0;

  color: black;
}
.headerOption:hover > h3 {
  opacity: 1;
  padding: 5px 0px;
}
@media screen and (max-width: 820px) {
  .headerOption > .MuiSvgIcon-root {
    background-color: #64ffb9;
    border-radius: 25px;
    padding: 5px;
    color: black;
  }

  .headerOption > h3 {
    padding-left: 5px;
    font-size: 15px;
    opacity: 0;
    transition: transform 0.5s ease-in;
  }
  .headerOption:hover {
    background-color: #64ffb9;
    border-radius: 25px;
    width: 100px;
    color: black;
  }

  .headerOption > h3:hover {
    font-size: 15px;
    opacity: 1;
  }
}
