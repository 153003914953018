.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.contact > h1 {
  font-size: 64px;
  font-weight: 800;
  color: #e6f1ff;
  font-family: poppins;
  margin-bottom: 20px;
}
.contact__page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px 15%;
  margin-top: 20px;
}
.contact__intro > h2 {
  font-family: poppins;
  font-size: 36px;
  color: #e6f1ff;
  font-weight: 600;
}
.contact__intro > p {
  font-size: 18px;
  color: #e6f1ff;
  margin: 20px 0px;
}
.contact__email {
  display: flex;
  align-items: center;
}
.contact__logo {
  margin-right: 10px;
}
.contact__logo > .MuiSvgIcon-root {
  height: 55px;
  width: 55px;
  color: #64ffda;
}

.contact__emailInfo > h3 {
  font-weight: 400;
  color: #8892b0;
}
.contact__emailInfo > p {
  font-weight: 600;
  font-size: 15px;
  color: #e6f1ff;
}
.contact__form > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form > input {
  margin: 15px;
  width: 100%;
  padding: 15px 30px;
  border: 1px solid #64ffda;
  border-radius: 20px;
  color: #e6f1ff;
  background-color: black !important;
}
.message {
  padding: 40px 30px;
}
form > button {
  width: 150px;
  height: 55px;
  border-radius: 15px;
  border: 1px solid #64ffda;
  background-color: transparent;
  color: #64ffda;
  font-family: PT Mono;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
.contact__send {
  color: #64ffda;
  font-size: 46px;
  font-family: PT Mono;
}
@media screen and (max-width: 414px) {
  .contact > h1 {
    font-size: 36px;
  }
  .contact__intro > h2 {
    font-size: 24px;
  }
  .contact__intro > p {
    font-size: 15px;
  }
  .contact__email {
    align-items: flex-start;
  }
  .contact__logo > .MuiSvgIcon-root {
    height: 35px;
    width: 35px;
  }
  .contact__emailInfo > h3 {
    font-size: 15px;
  }
  .contact__emailInfo > p {
    font-size: 13px;
  }
  form > input {
    margin: 10px 15px;
  }
  .message {
    padding: 40px 30px;
  }
}
@media screen and (max-width: 390px) {
  .contact {
    height: fit-content;
    width: fit-content;
  }
  .contact__page {
    margin-bottom: 20px;
  }
}
