.info {
  display: flex;
  height: 100vh;
}

.info__sidebar {
  display: flex;
  flex: 0.2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

a > .MuiSvgIcon-root {
  color: white;
  margin-top: 20px;
  height: 50px;
  width: 50px;
}
.info__bio {
  display: flex;
  flex: 0.8;
  flex-direction: column;
  justify-content: center;
}
.info__title {
  margin-bottom: 20px;
}
.info__title > p {
  color: #64ffda;
  font-family: PT Mono;
  font-size: 20px;
}
.info__title > h1 {
  font-size: 64px;
  color: whitesmoke;
  font-weight: 800;
  font-family: poppins;
}
.info__title > h2 {
  font-size: 48px;
  color: #8892b0;
  font-weight: 800;
  font-family: poppins;
}
.info__about > p {
  font-size: 20px;
  color: #8892b0;
  margin: 20px 0px;
  width: 70%;
}
.info__bio > a > button {
  width: 150px;
  height: 55px;
  border-radius: 17px;
  border: 1px solid #64ffda;
  background-color: transparent;
  color: #64ffda;
  font-family: PT Mono;
  margin-top: 20px;
  cursor: pointer;
}
button > a {
  color: inherit;
  text-decoration: none;
}

.icon {
  height: 35px;
  width: 35px;
  padding: 5px;
}

@media screen and (max-width: 820px) {
  .info {
    width: 100%;
  }
  .info__title > p {
    font-size: 13px;
  }
  .info__title > h1 {
    font-size: 36px;
  }
  .info__title > h2 {
    font-size: 30px;
  }
  a > .MuiSvgIcon-root {
    width: 25px;
    height: 25px;
  }
  .info__about > p {
    font-size: 15px;
  }
  .info__bio {
    flex: 1;
  }
}
@media screen and (max-width: 414px) {
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .info__sidebar {
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
    align-items: center;
  }
  .info__bio {
    padding: 0 10%;
    flex: 1;
  }
  .info__title > p {
    padding-bottom: 15px;
  }
  .info__title > h2 {
    font-size: 30px;
  }
  .info__about > p {
    width: 100%;
  }
}
@media screen and (max-width: 390px) {
  .info {
    justify-content: center;
  }
  .info__sidebar {
    align-items: flex-start;
  }
  .info__bio {
    justify-content: flex-start;
  }
  a > .MuiSvgIcon-root {
    margin-top: 0px;
  }
}
